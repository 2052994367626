/* @flow */
import * as React from 'react'
import ListOfItems from 'app/components/presentational/ListOfItems'
import windowView from 'shared/view/window'

const ProductItem = ({
    product,
    active,
    onClick,
    className,
    useOneTimeOfferLabel,
}: {
    active: boolean,
    onClick: Function,
    product: Object,
    className: string,
    useOneTimeOfferLabel?: boolean,
}) => {
    const testClass = className

    return (
        <div onClick={onClick} className={`${testClass} productSelection__item ${active ? 'is-active' : ''}`}>
            <div className="productSelection__label">
                <div className="u-flexAlignCenter u-spaceLeft">
                    <ProductItemRadio active={active} />
                    <div className="productSelection__subscriptionPeriod">
                        {useOneTimeOfferLabel
                            ? `${product.price} for ${product.label}`
                            : `${product.timePeriod} months`}
                    </div>
                </div>
                {useOneTimeOfferLabel ? null : (
                    <div className="u-spaceRight u-textRight u-flexGrow">{product.price} / month</div>
                )}
            </div>
            {product.savingsPercentage ? (
                <div className="productSelection__chip u-spaceRight">
                    <span>{`Save ${product.savingsPercentage}%`}</span>
                </div>
            ) : null}
        </div>
    )
}

const ProductItemRadio = ({ active }: { active: boolean }) => {
    const iconClass = active ? null : 'is-hidden'

    return (
        <div className="productSelection__radio" role="radio" aria-checked={active}>
            <div className={iconClass} aria-hidden="true">
                <i className="icon icon-tick-green" />
            </div>
        </div>
    )
}

type Props = {
    fieldName?: string,
    productList: Object[],
    selectedProduct?: string,
    useOneTimeOfferLabel?: boolean,
}

type State = {
    fieldName?: string,
    selectedProduct?: string,
    useOneTimeOfferLabel?: boolean,
}

class ProductTable extends React.PureComponent<Props, State> {
    constructor(props: Object) {
        super(props)
        this.state = {
            selectedProduct: this.props.selectedProduct
                ? this.props.selectedProduct
                : this.props.productList[this.props.productList.length - 1].value,
            fieldName: this.props.fieldName,
            useOneTimeOfferLabel: this.props.useOneTimeOfferLabel,
        }
    }

    onProductClick(product: Object) {
        this.setState({
            selectedProduct: product.value,
        })
    }

    render() {
        const selectedProduct = this.props.productList.find((product) => product.value === this.state.selectedProduct)

        const products = this.props.productList.map((product, i) => (
            <ProductItem
                key={product.price}
                className={`t-product-selection-item-${i + 1} t-rate-plan-${product.timePeriod}`}
                product={product}
                active={this.state.selectedProduct === product.value}
                onClick={() => this.onProductClick(product)}
                useOneTimeOfferLabel={this.state.useOneTimeOfferLabel}
            />
        ))

        products.push(
            <input
                key={this.state.fieldName}
                type="hidden"
                name={this.state.fieldName}
                value={this.state.selectedProduct}
            />
        )

        if (windowView.isAndroidDevice() && windowView.isWebView() && selectedProduct) {
            products.push(
                <div key="total" className="u-textCenter u-halfSpaceTop u-hideAboveTablet finePrint">
                    {selectedProduct.isFreeTrial === true || selectedProduct.isFreeTrial === '1'
                        ? 'After 30 days you will be charged'
                        : 'You will be charged the total amount of'}{' '}
                    <strong className="u-textNoWrap">{selectedProduct.totalPrice}</strong> for{' '}
                    <strong className="u-textNoWrap">{selectedProduct.timePeriod} months</strong>.
                </div>
            )
        }

        return (
            <ListOfItems>
                <div className="t-productSelection">{products}</div>
            </ListOfItems>
        )
    }
}

export default ProductTable
